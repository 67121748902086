import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '@components/Layout';
import SEO from '@components/seo';
import {get, kebabCase} from 'lodash';
import '@scss/main.scss';


export default function Shop({ data }) {
  const [filter, setFilter] = useState('all')
  const products = get(data, 'allShopifyProduct.nodes');
  const sortedProducts = products.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  const filterHandler = (e) => {
    const filter = e.target.dataset.filter;
    setFilter(filter);
  }

  const categoryNav = (products) => {
    const uniqueCategories = new Set();
    for (const product of products) {
      uniqueCategories.add(product.productType);
    }
    // console.log(uniqueCategories);
    if (uniqueCategories.size > 0) {
      return (
        <div className="pill-nav-wrapper">
          <ul>
            <li
              data-filter="all"
              className={filter === "all" ? "active" : ""}
              onClick={filterHandler}
            >
              All
            </li>
            {Array.from(uniqueCategories).map((category) => (
              <li
                key={category}
                className={filter === kebabCase(category) ? "active" : ""}
                data-filter={kebabCase(category)}
                onClick={filterHandler}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Layout baseRoute="shop">
      <SEO title="Shop Custom Graphic Tees and Prints" />

      {products && categoryNav(products)}

      <div className="product-grid">
        {sortedProducts &&
          sortedProducts.map((product, index) => {
            if (filter === 'all' || filter === kebabCase(product.productType)) {
              return (<Link
                key={index}
                to={`/shop/product/${product.handle}`}
              >
                <GatsbyImage
                  image={getImage(product.images[0].localFile)}
                  alt="alt text"
                />
                <h2 className="product-title">{product.title}</h2>
              </Link>)
          }})}
      </div>
      <div className="disclaimer">
        If you have any questions, please check out our{" "}
        <Link className="gradient" to="/faq">
          FAQ
        </Link>
        .
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    allShopifyProduct {
      nodes {
        productType
        title
        handle
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 250, placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        descriptionHtml
        description
        tags
      }
    }
  }
`;
